export const formatAddressString =(address: any): string => {
  const strArr: string[] = [];
  ['city', 'stateProvince', 'postalCode', 'country'].forEach(key => {
    if (Object.prototype.hasOwnProperty.call(address, key) && address[key] !== '') {
      strArr.push(address[key]);
    }
  });

  const addressStreetStr = address.street + (address.street1 ? ` ${address.street1}` : '');
  if (addressStreetStr && strArr.length) {
    return `${addressStreetStr} , ${strArr.join(', ')}`;
  } else if (addressStreetStr) {
    return addressStreetStr;
  } else if (strArr.length) {
    return `${strArr.join(', ')}`;
  } else {
    return '';
  }
}
