import { Inject, Injectable } from '@angular/core';
import { AG_GRID_KEY_TOKEN } from '@pinnakl/shared/constants';
import { LicenseManager } from 'ag-grid-enterprise';

@Injectable({
  providedIn: 'root'
})
export class Initializer {
  constructor(
    @Inject(AG_GRID_KEY_TOKEN) private readonly agGridKey: string,
  ) { }

  initialize(): void {
    this.setupExternalComponents();
  }

  private setupExternalComponents(): void {
    LicenseManager.setLicenseKey(this.agGridKey);
  }
}
