<ng-container *ngIf="simpleMode; else extendedMode">
  <ng-template *ngTemplateOutlet="spinner"></ng-template>
</ng-container>
<ng-template #extendedMode>
  <ng-container *ngIf="customLoading$; else usingGlobalSpinner">
    <ng-container *ngIf="customLoading$ | async">
      <ng-template *ngTemplateOutlet="spinner"></ng-template>
    </ng-container>
  </ng-container>
  <ng-template #usingGlobalSpinner>
    <ng-container
      *ngIf="{
        visible: visible$ | async,
        showMainSpinner: showMainSpinner$ | async
      } as visibilityConditions"
    >
      <ng-container *ngIf="visibilityConditions.visible && !visibilityConditions.showMainSpinner">
        <ng-template *ngTemplateOutlet="spinner"></ng-template>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #spinner>
  <div class="pnkl-spinner overlay" [ngStyle]="{ backgroundSize: size, backgroundColor }"></div>
</ng-template>
