import { Inject, Injectable } from '@angular/core';
import { getAppInstanceToken, getBooleanFromString } from '@pinnakl/shared/util-helpers';
import { BehaviorSubject } from 'rxjs';
import { IUser, User, UserInfoApi, UserLoginFromApi } from '@pinnakl/shared/types';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  isResetPassword$ = new BehaviorSubject<{ isLoginPass: boolean; isPageReady: boolean }>({
    isLoginPass: false,
    isPageReady: false
  });
  userAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly _usersEndpoint = 'entities/users';
  private readonly _crm_blocked_ips = 'entities/crm_blocked_ips';
  constructor(
    private readonly wsp: WebServiceProvider,
    @Inject(ENVIRONMENT_SERVICE) private readonly environmentService: EnvironmentService
  ) {}

  getUser(): IUser | null {
    const userInSession = localStorage.getItem(this.userTokenStorageKey);
    try {
      return userInSession ? JSON.parse(userInSession) : null;
    } catch (e) {
      return null;
    }
  }

  setUser(user: IUser): void {
    localStorage.setItem(this.userTokenStorageKey, JSON.stringify(user));
    localStorage.setItem('name', user.firstName);
    this.userAvailable$.next(true);
  }

  removeUser() {
    this.userAvailable$.next(false);
    localStorage.removeItem(this.userTokenStorageKey);
  }

  async getBlockedIps() {
    return await this.wsp.getHttp<any[]>({
      endpoint: 'entities/crm_blocked_ips',
      params: {
        fields: ['id', 'ipaddress']
      }
    });
  }

  async postBlockedIps(payload) {
    return await this.wsp.postHttp<any[]>({
      endpoint: this._crm_blocked_ips,
      body: payload
    });
  }

  async removeBlockedIps(ipId) {
    return await this.wsp.deleteHttp<any[]>({
      endpoint: `${this._crm_blocked_ips}/${ipId}`
    });
  }

  async getFullUserData(id: number): Promise<User | null> {
    const users = await this.wsp.getHttp<UserInfoApi[]>({
      endpoint: this._usersEndpoint,
      params: {
        fields: ['*'],
        filters: [
          {
            key: 'id',
            type: 'EQ',
            value: [id.toString()]
          }
        ]
      }
    });

    const userFromApi = Array.isArray(users) && users.length > 0 ? users[0] : null;

    if (userFromApi) {
      return this.formatUserFromApi(userFromApi);
    }

    return null;
  }

  async getFullUserDataByEmail(email: string): Promise<User> {
    const users = await this.wsp.getHttp<any[]>({
      endpoint: this._usersEndpoint,
      params: {
        fields: [
          'otpchannel',
          'authtype',
          'clientadmin',
          'clientid',
          'email',
          'firstname',
          'id',
          'isdevuser',
          'tokenreauthinterval',
          'lastname',
          'newarchitecture',
          'password',
          'phone',
          'roleid',
          'timezone',
          'tradingaccess',
          'username'
        ],
        filters: [
          {
            key: 'email',
            type: 'EQ',
            value: [email]
          }
        ]
      }
    });

    const userFromApi = Array.isArray(users) && users.length > 0 ? users[0] : null;

    if (userFromApi) {
      return this.formatUserFromApi(userFromApi);
    }

    return userFromApi;
  }

  putUser(putJson: any): Promise<UserInfoApi> {
    return this.wsp.putHttp({ endpoint: this._usersEndpoint, body: putJson });
  }

  formatLoginUser({ user, token, username }: UserLoginFromApi & { username: string }): IUser {
    return {
      ...user,
      tokenReAuthInterval: user.tokenReauthInterval,
      token,
      username,
      fullName: `${user.firstName} ${user.lastName}`
    };
  }

  // TODO refactor - unify with above
  formatUserFromApi(userFromApi: UserInfoApi): User {
    const user = new User(
      parseInt(userFromApi.clientid),
      userFromApi.firstname,
      `${userFromApi.firstname || ''} ${userFromApi.lastname || ''}`,
      parseInt(userFromApi.id),
      userFromApi.lastname,
      '',
      userFromApi.username,
      userFromApi.email,
      userFromApi.phone,
      userFromApi.otpchannel,
      userFromApi?.pinnaklclientname,
      getBooleanFromString(userFromApi.complianceaccess ?? '') ?? false
    );

    user.authType = userFromApi.authtype;
    user.clientAdmin = getBooleanFromString(userFromApi.clientadmin ?? '') ?? false;
    user.tradingAccess = getBooleanFromString(userFromApi.tradingaccess ?? '') ?? false;

    user.tokenReAuthInterval = +userFromApi['tokenreauthinterval'];
    user.timezone = userFromApi.timezone;
    user.isDevUser = getBooleanFromString(userFromApi.isdevuser ?? '') ?? false;

    return user;
  }

  validateUserResetPasswordModal(): void {
    const resetPassword = this.isResetPassword$.getValue();

    if (resetPassword.isLoginPass && !resetPassword.isPageReady) {
      this.isResetPassword$.next({ isLoginPass: true, isPageReady: true });
    }
  }

  get userTokenStorageKey(): string {
    const authConfig = this.environmentService.get('authConfig');
    const appName = this.environmentService.get('appName');
    return `user-${getAppInstanceToken(authConfig?.configId, location?.host, appName)}`;
  }
}
