export enum BrokerEnvironmentType {
  UAT = 'UAT',
  PROD = 'PROD'
}

export class OutsourceDestination {
  constructor(public id: number, public outsourcedBrokerCode: string) {}
}
export class Broker {
  public showAlgo: boolean;
  public atdlBrokerCode: string;

  constructor(
    public id: number,
    public brokerCode: string,
    public brokerName: string,
    public sendAllocations: boolean,
    public clearingIndicator: boolean,
    public clearingBrokerId: number | null,
    public nsccCode: string,
    public fixNetBrokerCode: string,
    public environment: BrokerEnvironmentType,
    public isOutsourcedBroker: boolean,
    public allocationFormat: AllocationFormat,
    public outsourceDestinations: OutsourceDestination[],
  ) {}
}

export class BrokerFromApi {
  constructor(
    public id: string,
    public brokercode: string,
    public brokername: string,
    public sendallocations: string,
    public clearing_indicator: string,
    public clearingbrokerid: string,
    public nscccode: string,
    public fixnetbrokercode: string,
    public environment: BrokerEnvironmentType,
    public showalgo: string,
    public isoutsourcedbroker: string,
    public allocationformat: AllocationFormat,
    public atdlbrokercode: string
  ) {}
}

// eslint-disable-next-line no-shadow
export enum AllocationFormat {
  Text = 'Text',
  Excel = 'Excel'
}
