import { combineLatest, filter, map, Observable, switchMap, take } from 'rxjs';

export function isNeitherNullNorUndefined<T>(value: T): value is NonNullable<T> {
  return value != null;
}

export const getLoadableEntities$ = <T>(
  dataSelector$: Observable<T> ,
  isLoaded$: Observable<boolean>,
  isLoading$: Observable<boolean>,
  loadFunc: () => void
): Observable<T> => {
  return combineLatest([
    isLoaded$,
    isLoading$
  ]).pipe(
    map(([ loaded, loading ]) => {
      if (!loaded && !loading) {
        loadFunc();
      }
      return loaded && !loading
    }),
    filter(dataLoaded => dataLoaded),
    take(1),
    switchMap(() => dataSelector$),
  )
}
