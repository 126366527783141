import { inject, InjectionToken } from '@angular/core';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';
import { ILogService } from './models';
import { AppNames } from '@pinnakl/shared/types';
import { LogService } from './log.service';
import { DummyLogService } from './dummy-log.service';
import { WebServiceProvider } from '@pinnakl/core/web-services';

export const SESSION_INSTANCE_ID = 'sii';

export const LOGGER = new InjectionToken('LOGGER', {
  factory(): ILogService {
    const env = inject<EnvironmentService>(ENVIRONMENT_SERVICE);
    const wsp = inject(WebServiceProvider);
    return env.get('appName') === AppNames.CRM_INVESTOR_PORTAL
      ? new LogService(wsp, env)
      : new DummyLogService()
  }
});
