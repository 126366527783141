import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class WebServiceUtility {
  formatEntity<TFromApi, T>(
    entityFromApi: TFromApi,
    fields: {
      apiName: string;
      name: string;
      type: string;
      isNullable: boolean;
    }[]
  ): T {
    const entity = {} as T;
    for (let field of fields) {
      if (field.type === 'string') {
        entity[field.name] = entityFromApi[field.apiName];
      } else if (field.type === 'number') {
        let fieldItem = parseFloat(entityFromApi[field.apiName]);
        entity[field.name] = !isNaN(fieldItem) ? fieldItem : null;
      } else if (field.type === 'boolean') {
        let fieldItem = entityFromApi[field.apiName];
        entity[field.name] = fieldItem === 'True';
      } else if (field.type === 'Date') {
        let fieldItem = moment(
          entityFromApi[field.apiName],
          'MM/DD/YYYY hh:mm:ss a'
        );
        entity[field.name] = fieldItem.isValid() ? fieldItem.toDate() : null;
      }
    }
    return entity;
  }

  getEntityForServiceRequest<TFromApi, T>(
    entity: Partial<T>,
    fields: {
      apiName: string;
      name: string;
      type: string;
      isNullable: boolean;
    }[]
  ): Partial<TFromApi> {
    let entityForApi: Partial<TFromApi> = {};
    for (let field of fields) {
      if (entity[field.name] !== undefined) {
        if (field.type === 'string') {
          if (field.isNullable) {
            entityForApi[field.apiName] =
              entity[field.name] === null ? 'null' : entity[field.name];
          } else {
            entityForApi[field.apiName] = entity[field.name];
          }
        } else if (field.type === 'number') {
          if (field.isNullable) {
            entityForApi[field.apiName] =
              entity[field.name] === null
                ? 'null'
                : entity[field.name].toString();
          } else {
            entityForApi[field.apiName] = entity[field.name].toString();
          }
        } else if (field.type === 'boolean') {
          if (field.isNullable) {
            entityForApi[field.apiName] =
              entity[field.name] === null
                ? 'null'
                : entity[field.name]
                ? '1'
                : '0';
          } else {
            entityForApi[field.apiName] = entity[field.name] ? '1' : '0';
          }
        } else if (field.type.toLowerCase().includes('date')) {
          if (field.isNullable) {
            entityForApi[field.apiName] =
              entity[field.name] === null
                ? 'null'
                : moment(entity[field.name]).format('MM/DD/YYYY hh:mm:ss a');
          } else {
            entityForApi[field.apiName] = moment(entity[field.name]).format(
              'MM/DD/YYYY hh:mm:ss a'
            );
          }
        }
      }
    }
    return entityForApi;
  }
}
