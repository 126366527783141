import { Inject, Injectable } from '@angular/core';
import packageJson from './../../../../package.json';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { SESSION_INSTANCE_ID } from './const';
import { ILogService } from './models';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';

@Injectable({ providedIn: 'root' })
export class LogService implements ILogService {
  private ADD_IN_ERROR_LOGS_URL = '/outlook_addin_error_logs';
  private readonly version = packageJson.version;

  constructor(
    private readonly wsp: WebServiceProvider,
    @Inject(ENVIRONMENT_SERVICE) private readonly envService: EnvironmentService,
  ) {
    this.checkSessionInstanceId();
  }

  post(body: any): void {
    const message = {
      loggedDate: moment.utc().toDate(),
      appVersion: this.version,
      sessionInstanceId: sessionStorage.getItem(SESSION_INSTANCE_ID),
      href: window.location.href,
      appName: this.envService.get('appName'),
      ...body,
    };
    this.wsp.post({
      endpoint: this.ADD_IN_ERROR_LOGS_URL,
      body: message,
    }).subscribe();
  }

  private createSessionInstanceId(): string {
    return uuidv4();
  }

  private checkSessionInstanceId(): void {
    const sessionInstanceId = sessionStorage.getItem(SESSION_INSTANCE_ID);
    if (!sessionInstanceId) {
      sessionStorage.setItem(SESSION_INSTANCE_ID, this.createSessionInstanceId());
    }
  }
}
