export interface SecuritySearchResult {
  id: number;
  assetType: string;
  countryOfIncorporationCode: string;
  countryOfQuotationCode: string;
  cusip: string;
  description: string;
  isin: string;
  maturity: string;
  securityMarketId: number;
  sedol: string;
  ticker: string;
  // TODO: securityV2ToSearchResult, securityV1ToSearchResult?
  longPosition?: number;
  shortPosition?: number;
  position: number;
  // TODO: remove assetTypeId?
  assetTypeId?: number;
}
