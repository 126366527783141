import { Component } from '@angular/core';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';

@Component({
  selector: 'pinnakl-spinner',
  templateUrl: 'global-spinner.component.html',
  styleUrls: ['global-spinner.component.scss']
})
export class PinnaklSpinnerComponent {
  visible$ = this.spinner.showMainSpinner$;

  constructor(private readonly spinner: PinnaklSpinnerService) {}
}
