import { Account } from './account/account.model';

interface RuleEnumPredicate {
  options: number;
  predicateLabel: number;
  predicateType: number;
  predicateValue: number;
  operator: number | number[];
}

class RuleCriterias {
    id: number;
    ruleId: number;
    comparisonPredicate: string;
    comparisonOperator: number;
    comparisonValue: string;
}

export class Restriction {
    id: number;
    clientId: number;
    ruleType: number;
    description: string;
    enforcement: number;
    active: boolean;
}
export class ExposureLimits extends Restriction {
    predicate: string;
    limit: number;
    operator: number;
    andRuleCriterias: RuleCriterias[];
    orRuleCriterias: RuleCriterias[];
    ruleAccountMemberships: Array<{id: number, ruleId: number, accountId: number, account: Account}>;
}

export interface ExposureLimitsPredicates {
  exposureLimitPredicates: RuleEnumPredicate[];
  filterPredicates: RuleEnumPredicate[];
}
