import {
  BasePreset,
  BasePresetWithModuleBuilder,
  ParsedPreset,
  PmsPresetsTypes,
  Preset,
  PresetModules
} from '@pinnakl/shared/types';

export const getObjectSimpleCopyOrUndefined = <T>(
  obj: T | undefined
): T | undefined => {
  return obj ? { ...obj } : undefined;
}

/**
 * @param obj object that you would like to clean up from empty properties
 * @description provide T - generic interface for provided object
 * @returns Partial - Object without null fields
 * @example const obj = { a: 1, b: "b", c: null, d: [ ] } => @returns const obj = { a: 1, b: "b", d: [ ] }
 */
export const removeEmptyKeyValuesFromObject= <T>(obj: T): Partial<T> => {
  return Object.keys(obj as unknown as Record<string, null | string>)
    .filter((k) => obj[k] !== null)
    .reduce((acc, k) => {
      acc[k] = obj[k];
      return acc;
    }, {} as Partial<T>);
}

/**
 * Mostly used in reducers to set loaded and loading fields in store
 * @param loaded
 * @param loading
 */
export const setLoadedAndLoadingFields = (loaded: boolean, loading: boolean) => ({
  loaded,
  loading
});

/**
 * Presets helpers
 * @param presetObj
 */
export const getPresetWithParsedConfigValue = <T>(
  presetObj: Preset | null
): ParsedPreset<T> | null => {
  let presetObjWithParsedConfigValue: ParsedPreset<T> | null = null;
  if (presetObj) {
    try {
      presetObjWithParsedConfigValue = {
        ...presetObj,
        parsedConfigValue: JSON.parse(presetObj.configValue)
      };
    } catch (e) {
      console.error('preset.configValue can\'t be parsed. Value: ', { presetObj });
    }
  }
  return presetObjWithParsedConfigValue;
}

export const buildBasePreset = (
  module: PresetModules,
): BasePresetWithModuleBuilder => (
  configName: PmsPresetsTypes,
  configValue: string
): BasePreset => ({
  module,
  configName,
  configValue
});
