import { Account } from '../account/account.model';
import { AUM } from '../aum.model';
import { CashBalance } from '../cash-balance.model';
import { CustomAttribute } from '../custom-attributes';

export class PMSFxRate {
  constructor(
    public currencyPair: string,
    public fxRate: number,
    public fxRateQuotedInUSD: number,
    public priceDate: string,
    public primaryCurrency: string,
    public secondaryCurrency: string
  ) {}
}

export interface PmsConfig {
  accountCash: CashBalance[];
  accounts: Account[];
  auMs: Partial<AUM>[];
  portfolioStatus: {
    accountPortfolioStatusList: {
      accountId: number;
      cash: { cashPrevClose: number; cashNow: number };
      positionsWithPL: {
        accountId: number;
        clientId: number;
        cost: number;
        currentPosition: number;
        customAttribId: number;
        direction: 'LONG' | 'SHORT';
        mark: number;
        quantity: number;
        realizedPnl: number;
        securityId: number;
        securityMarketId: number;
        unrealizedPnl: number;
        subAccountId: number | null;
      }[];
    }[];
  };
  benchmarkIndexIntradayPrices: any[];
  customAttributes: CustomAttribute[];
  fxRates: PMSFxRate[];
  intradayPL: any[];
  prices: any[];
  rebalanceConfig: any[];
  reportData: any[];
  reportInfo: any[];
  tradeWorkflowSpecs: Record<string, any>;
}
