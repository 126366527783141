export interface PositionDataItem {
  AccountCode: string;
  AccountId: number;
  Analyst: string;
  AssetType: string;
  Cost: number;
  Coupon: number;
  CurrentPosition: number;
  Cusip: string;
  CustomAttributeId: number;
  Delta: number;
  Description: string;
  Direction: 'Long' | 'Short';
  FolderCode: string;
  Gamma?: any;
  High?: any;
  Identifier: string;
  ImpliedVol?: any;
  Isin: string;
  LMVLocal: number;
  LMVUSD: number;
  LMVUSDLast: number;
  LMVUSDPCT: number;
  LoanId: string;
  LocalCurrency: string;
  LongPosition: number;
  Low?: any;
  MVLocal: number;
  MVUSD: number;
  MVUSDLast: number;
  MVUSDLastPct: number;
  MVUSDPct: number;
  Mark: number;
  Maturity: any;
  MoodyRating: string;
  Mtd_Pnl: number;
  Multiplier: number;
  OrgTicker: string;
  PriceLast: number;
  PricingMethod?: any;
  SMVLocal: number;
  SMVUSD: number;
  SMVUSDLast: number;
  SMVUSDPCT: number;
  SODPosition: number;
  SandPRating: string;
  Sector: string;
  SecurityId: number;
  SecurityMarketId: number;
  SecurityType: string;
  Sedol: string;
  ShortPosition: number;
  Strategy: string;
  Ticker: string;
  Trader: string;
  UndPrc: number;
  UnderlyingSecId: number;
  UpdatedAt: Date;
  Ytd_Pnl: number;
  SubAccountCode: string;
  SubAccountId: number;
  pnlRealized: number;
  pnlUnRealized: number;
}
