export interface ReportColumnInterface {
  caption: string;
  convertToBaseCurrency: boolean;
  decimalPlaces: number | null;
  filterValues: Date | number | string[] | null;
  groupOrder: number | null;
  id: string;
  isAggregating: boolean;
  name: string;
  renderingFunction: string;
  reportId: string;
  sortAscending: boolean | null;
  sortOrder: number | null;
  type: string;
  formula: string;
  viewOrder: number | null;
}

export interface ClientReportColumnInterface {
  caption: string;
  clientReportId: string | null;
  decimalPlaces: number | null;
  filterValues: Date | number | string[] | null;
  groupOrder: number | null;
  id: string;
  isAggregating: boolean;
  name: string;
  reportColumnId: string;
  sortAscending: boolean | null;
  sortOrder: number | null;
  type: string;
  viewOrder: number | null;
}
