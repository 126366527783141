export class ClientConnectivityFromApi {
  adminid: string;
  custodianid: string;
  entity: string;
  entitytype: string;
  id: string;
  recon_indicator: string;
  stockloan_indicator: string;
  tradefile_indicator: string;
}
