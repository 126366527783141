export interface ModelSecurity {
  SecMarketId: number;
  AssetType: string;
  Ticker: string;
  Currency: string;
  CurrentWeight: number;
  Price: number;
  Description: string;
}

export class TargetAccount {
  public tradeCash: number;
  constructor(
    public accountId: number,
    public currentMV: number,
    public targetMV: number,
    public trade: number,
    public price: number
  ) {}
}

export class TargetSecurity {
  constructor(public secMarketId: number, public targetAccounts: TargetAccount[]) {}

  getBuys(): number {
    return this.targetAccounts
      .filter(acc => acc.trade >= 0)
      .reduce((sum, current) => sum + current.trade, 0);
  }
  getSells(): number {
    return this.targetAccounts
      .filter(acc => acc.trade < 0)
      .reduce((sum, current) => sum + current.trade, 0);
  }
}

export class ModelingAccountCash {
  constructor(public accountId: number, public tradeCash: number) {}
}
export class TargetPortoflio {
  public securityTargets: TargetSecurity[];
  public accountCash: ModelingAccountCash[];

  getTotalTradeCash(accountId: number) {
    const cash = this.accountCash?.find(elem => elem.accountId === accountId)?.tradeCash;
    return cash;
  }
}
