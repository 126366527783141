import {Component, Inject} from '@angular/core';
import { Router } from '@angular/router';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';

@Component({
  selector: 'pnkl-frontend-not-found-component',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class PageNotFoundComponent {
  appHost = this.environmentService.get('vdrFileAppHost');
  docId;
  constructor(
    private router: Router,
    @Inject(ENVIRONMENT_SERVICE) private readonly environmentService: EnvironmentService
  ) {}
  goToPage() {
    this.router.navigateByUrl(`/${this.docId}`);
  }
}
