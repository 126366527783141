import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';
import { Observable } from 'rxjs';

@Component({
  selector: 'pinnakl-local-spinner',
  templateUrl: 'local-spinner.component.html',
  styleUrls: ['local-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalSpinnerComponent {
  @Input() simpleMode = false;
  @Input() customLoading$?: Observable<boolean>;
  @Input() size = '100px';
  @Input() backgroundColor = 'rgba(255, 255, 255, 0.2)';

  visible$ = this.spinnerService.visible$;
  showMainSpinner$ = this.spinnerService.showMainSpinner$;

  constructor(private readonly spinnerService: PinnaklSpinnerService) {}
}
