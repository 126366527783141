import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PinnaklSpinnerService {
  private skipWholePageSpinner = false;
  private spinnerVisible$ = new BehaviorSubject(false);

  visible = false;
  visible$ = this.spinnerVisible$.pipe(debounceTime(100), distinctUntilChanged());
  showMainSpinner$ = this.visible$.pipe(map(visible => visible && !this.skipWholePageSpinner));

  spin(skipWholePageSpinner = false): void {
    this.skipWholePageSpinner = skipWholePageSpinner;
    this.visible = true;
    this.spinnerVisible$.next(true);
  }
  stop(): void {
    this.visible = false;
    this.spinnerVisible$.next(false);
  }
}
