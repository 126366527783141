import { ApiUser, UserAuthType } from "./user.model";

export interface UserLoginFromApi {
  application: string;
  fingerprint: string;
  lastAccessTime: string;
  location: {
    as: string;
    city: string;
    country: string;
    countryCode: string;
    isp: string;
    lat: number;
    lon: number;
    org: string;
    query: string;
    region: string;
    regionName: string;
    status: string;
    timezone: string;
    zip: string;
  };
  token: string;
  user: ApiUser;
}

export interface UserInfoApi {
  applicationaccesslevel: string;
  authtype: UserAuthType;
  clientadmin: 'True' | 'False';
  clientid: string;
  complianceaccess: 'True' | 'False';
  email: string;
  features: string
  firstname: string;
  id: string;
  isdevuser: 'True' | 'False';
  lastname: string;
  newarchitecture: 'True' | 'False';
  otpchannel: string;
  otpsecret: string;
  password: string;
  passwordresetrequired: 'True' | 'False';
  phone: string;
  pinnaklclientname: string;
  clientPrefix: string;
  roleid: string;
  timezone: string;
  tokenreauthinterval: string
  tradingaccess: 'True' | 'False';
  username: string;
}
