// eslint-disable-next-line max-classes-per-file
import { Account } from './account/account.model';

export class TradeComplianceRule {
  constructor(public id: number, public enforcement: RuleEnforcementType, public description: string, public ruleCriteria: RuleCriteria[], public active: boolean, public accountMemberships: TradeRuleAccountMembership[]) {}
}

export class TradeComplianceRuleFromApi {
  constructor(public id: string, public enforcement: string, public description: string, public active: string) {}
}

export class RuleCriteria {
  constructor(public id: number, public ruleId: number, public predicate: string, public operator: string, public value: string) {}
}

export class RuleCriteriaFromApi {
  constructor(public id: string, public ruleid: string, public predicate: string, public operator: string, public value: string) {}
}

export class TradeRuleAccountsFromApi {
  constructor(public id: string, public ruleid: string, public accountid: string) {}
}

export class TradeRuleAccountMembership {
  constructor(public id: number, public ruleId: number, public account: Account) {}
}

export enum RuleEnforcementType {
  SOFT = 'SOFT',
  HARD = 'HARD'
}

export class RTLMembership {
  constructor(public id: number, public account: Account) {}
}
export class RestrictedTradingListAccountMemberships {
  constructor(public rtlMemberships: RTLMembership[]) {}
}
