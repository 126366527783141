import { animate, state, style, transition, trigger, } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'pnkl-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: [],
  animations: [
    trigger('src', [
      state('*', style({ opacity: 1 })),
      state('void', style({ opacity: 0 })),
      transition('* => *', animate('400ms')),
    ]),
  ],
})
export class AppComponent {}
