import { sumBy } from 'lodash';

export const buildGridPinnedBottomData = <T extends object>(
  columns: { isAggregating?: boolean | null; name: string }[],
  rows: T[]
): T[] => {
  if (!columns) {
    return [];
  }
  // To add sum of trade cost column (from rebalancing)
  columns = columns.concat([
    {
      isAggregating: true,
      name: 'tradecost'
    }
  ]);
  const reportAggregation = columns.reduce((acc, { isAggregating, name }) => {
    acc[name] = '';
    if (isAggregating && name.toLowerCase() !== 'mark') {
      acc[name] = sumBy(rows, name);
    }
    return acc;
  }, {} as T);
  for (const key of Object.keys(reportAggregation)) {
    if (reportAggregation[key] === null) {
      reportAggregation[key] = '';
    }
  }

  return [reportAggregation];
};
