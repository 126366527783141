export const getBooleanFromString = (value: string): boolean | null => {
  return value.toLowerCase() === 'true' ? true : value.toLowerCase() === 'false' ? false : null;
};

export const stringBooleanToBoolean = (value: string): boolean => {
  return value === 'True' || value === 'true';
}

export const stringBooleanToBooleanOrNull = (value: string): boolean | null => {
  return value === '' ? null : (value === 'True' || value === 'true');
}

export const stringToIntegerNumberOrNull = (value: string): number | null => {
  const intValue = parseInt(value, 10);
  return isNaN(intValue) ? null : intValue;
}

export const stringToFloatNumberOrNull = (value: string): number | null => {
  const floatValue = parseFloat(value);
  return isNaN(floatValue) ? null : floatValue;
}

export const stringToDateOrNull = (value: any): Date | null => {
  return value ? new Date(value) : null;
}

export const compareNumeric = (num1: number, num2: number): boolean => {
  let num1Updated: number | null = num1;
  let num2Updated: number | null = num2;
  if (isNaN(num1)) {
    num1Updated = null;
  }
  if (isNaN(num2)) {
    num2Updated = null;
  }
  return num1Updated === num2Updated;
}

export const compareStrings = (string1?: string | null, string2?: string| null): boolean => {
  return (!string1 && !string2) ? true : (string1 === string2);
}

export const isUndefinedOrNull = (value: any): boolean => {
  return value === undefined || value === null;
}
