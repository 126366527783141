<div class="notFound">
  <div>
    <h1>Pinnakl Data Room</h1>
    <p class="no-found">No document found</p>
    <p class="no-found">Please add a <b>Document ID</b> to the <b>URL</b> to view the document</p>

    <div class="input-container">
      <span class="placeholder">{{appHost}}/clientcode/</span>
      <input type="text" placeholder="DocumentID goes here" [(ngModel)]="docId" (keyup.enter)="goToPage()">
    </div>
  </div>
</div>
