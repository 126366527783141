export class PbAccountFromApi {
  accountcode: string;
  accountid: string;
  accounttype: string;
  custodianaccountnum: string;
  custodiancode: string;
  custodianid: string;
  custodianname: string;
  id: string;
  recon: string;
}
