import { EnvNames } from '@pinnakl/core/environment';

export const vdrFileAppHosts = {
  [EnvNames.local]: 'https://ir.dev.pinnakl.com',
  [EnvNames.dev]: 'https://ir.dev.pinnakl.com',
  [EnvNames.prod]: 'https://ir.pinnakl.com',
}

export const fileServiceUrls = {
  [EnvNames.local]: 'https://appsvc-externalservices-dev.azurewebsites.net',
  [EnvNames.dev]: 'https://appsvc-externalservices-dev.azurewebsites.net',
  [EnvNames.prod]: 'https://external-services-prod.pinnakl.com',
}
