export enum PresetModules {
  Pms = 'pms',
  Oms = 'oms',
  Ems = 'ems',
  Crm = 'crm',
}

export enum PmsPresetsTypes {
  PmsWidgetsOrder = 'pmsWidgetsOrder',
  PmsGridColumns = 'pmsGridColumns',
  PmsGridSelectedColumnsPreset = 'pmsGridSelectedColumnsPreset',
  PmsFontSize = 'pmsFontSize',
  PmsSortType = 'pmsSortType',
}

// Will add other modules config names when those modules will be refactored
// PmsPresetsTypes | OmsPresetsTypes | EmsPresetsTypes etc
export type PresetConfigName = PmsPresetsTypes;

export interface BasePreset {
  userid?: string;
  module: PresetModules;
  configName: PresetConfigName;
  configValue: string;
}
export interface Preset extends BasePreset {
  userId?: number;
  id: number;
}
export interface ParsedPreset<T> extends Preset {
  parsedConfigValue: T
}

export type BasePresetWithModuleBuilder = (configName: PmsPresetsTypes, configValue: string) => BasePreset;
