import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestorService } from './investor.service';
import { InvestorPortalService } from './investor-portal.service';
import { InvestorRelationsService } from './investor-relations.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    InvestorService,
    InvestorPortalService,
    InvestorRelationsService
  ]
})
export class InvestorModule {}
