import { ActionReducer } from '@ngrx/store';

import {
	ClearStore,
  ClearStoreActionTypes
} from './clear-store.actions';

export function clearStore(
  reducer: ActionReducer<any>
): (state: any, action: typeof ClearStore) => any {
  return function(state: any, action: typeof ClearStore): any {
    let stateToSet = state;
    if (action.type === ClearStoreActionTypes.ClearStore) {
      stateToSet = {};
    }

    return reducer(stateToSet, action);
  };
}
