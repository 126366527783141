import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';

@Injectable({
  providedIn: 'root'
})
export class MarksService {
  private readonly _pricesLatestMark = 'v3/entities/portfolio_latest_prices';
  constructor(private readonly _wsp: WebServiceProvider) {}

  public getPortfolioRecentPrices(): Promise<any> {
    return this._wsp.getHttp({ endpoint: this._pricesLatestMark });
  }
}
