export class Price {
  constructor(
    public ask: string,
    public bid: string,
    public entryMethod: string,
    public evalTime: string,
    public id: string,
    public include: boolean,
    public mid: number,
    public priceDate: Date,
    public securityMarketId: string,
    public source: string,
    public sourceName: string,
    public currencyCode: string,
    public fxRate: number,
    public priceBase: number,
    public closeLocal: number
  ) {}
}

export class PricingSource {
  constructor(
    public exclusive: boolean,
    public fileLoad: string,
    public id: string,
    public manuallyInsertable: boolean,
    public name: string,
    public sourceType: string = ''
  ) {}
}

export class Position {
  constructor(
    public assetType: string,
    public change: number,
    public comment: string,
    public commentId: string,
    public description: string,
    public identifier: string,
    public mid: number,
    public prevMid: number,
    public priceType: string,
    public securityMarketId: string,
    public ticker: string,
    public closeLocal: number,
    public prevCloseLocal: number,
    public currencyCode: string,
    public fxRate: number,
    public priceBase: number,
    public country_of_quotation: string
  ) {}
}

export class PositionNew {
  constructor(
    public assetType: string,
    public change: number,
    public comment: string,
    public commentId: string,
    public description: string,
    public identifier: string,
    public mid: number,
    public prevMid: number,
    public priceType: string,
    public securityMarketId: string,
    public ticker: string,
    public prices: Price[],
    public priceSource: string,
    public closeLocal: number,
    public prevCloseLocal: number,
    public currencyCode: string,
    public fxRate: number,
    public priceBase: number,
    public country_of_quotation: string
  ) {}
}

export class PricingComment {
  constructor(
    public id: string,
    public priceDate: Date,
    public securityMarketId: string,
    public comment: string
  ) {}
}
