/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, firstValueFrom, throwError } from "rxjs";
import {
  GetHttpRequest,
  PutHttpRequest,
  PostHttpRequest,
  DeleteHttpRequest
} from '@pinnakl/shared/types';
import { HTTP_SERVER_URL } from '@pinnakl/shared/constants';

const enum REQUEST_TYPE {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete'
}

@Injectable({
  providedIn: 'root'
})
export class PinnaklHttpService {
  constructor(
    @Inject(HTTP_SERVER_URL) private readonly _HTTP_URL: string,
    private readonly http: HttpClient
  ) {}

  static getEndpointUrlWithParams(params, endpoint): string {
    if (!params) {
      return endpoint;
    }
    const paramsWithReplacedAmpersand = JSON.stringify(params).replace('&', '%26');
    return `${endpoint}?options=${encodeURI(paramsWithReplacedAmpersand)}`;
  }

  delete<T>({ endpoint, body }: DeleteHttpRequest): Promise<T> {
    return firstValueFrom<T>(
      this.sendHttpRequest<any>(REQUEST_TYPE.DELETE, endpoint, {
        body
      })
    ).catch(e => this.handleErrorPromise<T>(e, REQUEST_TYPE.DELETE));
  }

  deleteObservable<T>({ endpoint, body }: DeleteHttpRequest): Observable<T> {
    return this.sendHttpRequest<T>(REQUEST_TYPE.DELETE, endpoint, {
      body
    }).pipe(catchError(error => this.handleError<T>(error, REQUEST_TYPE.DELETE)));
  }

  get<T>({ params, endpoint }: GetHttpRequest): Promise<T> {
    const preparedEndpointUrl = PinnaklHttpService.getEndpointUrlWithParams(params, endpoint);
    return firstValueFrom<T>(
      this.sendHttpRequest<T>(REQUEST_TYPE.GET, preparedEndpointUrl, {})
    ).catch(e => this.handleErrorPromise<T>(e, REQUEST_TYPE.GET));
  }

  getObservable<T>({ params, endpoint }: GetHttpRequest): Observable<T> {
    const preparedEndpointUrl = PinnaklHttpService.getEndpointUrlWithParams(params, endpoint);
    return this.sendHttpRequest<T>(REQUEST_TYPE.GET, preparedEndpointUrl, {}).pipe(
      catchError(error => this.handleError<T>(error, REQUEST_TYPE.GET))
    );
  }

  post<T>({ body, endpoint }: PostHttpRequest): Promise<T> {
    return firstValueFrom<T>(
      this.sendHttpRequest<T>(REQUEST_TYPE.POST, endpoint, {
        body: body
      })
    ).catch(e => this.handleErrorPromise<T>(e, REQUEST_TYPE.POST));
  }

  postObservable<T>({ body, endpoint }: PostHttpRequest): Observable<T> {
    return this.sendHttpRequest<T>(REQUEST_TYPE.POST, endpoint, {
      body: body
    }).pipe(catchError(e => this.handleError<T>(e, REQUEST_TYPE.POST)));
  }

  put<T>({ endpoint, body }: PutHttpRequest): Promise<T> {
    return firstValueFrom<T>(
      this.sendHttpRequest<any>(REQUEST_TYPE.PUT, endpoint, {
        body: body
      })
    ).catch(e => this.handleErrorPromise<T>(e, REQUEST_TYPE.PUT));
  }

  putObservable<T>({ endpoint, body }: PutHttpRequest): Observable<T> {
    return this.sendHttpRequest<T>(REQUEST_TYPE.PUT, endpoint, {
      body: body
    }).pipe(catchError(e => this.handleError<T>(e, REQUEST_TYPE.PUT)));
  }

  private handleError<T>(error: any, requestType: REQUEST_TYPE): Observable<T> {
    console.log(`Error in ${requestType} request`);
    return throwError(() => error);
  }

  private handleErrorPromise<T>(error: any, requestType: REQUEST_TYPE): Promise<T> {
    console.log(`Error in ${requestType} request`);
    return Promise.reject(error) as Promise<T>;
  }

  private sendHttpRequest<T>(
    type: REQUEST_TYPE,
    endpoint: string,
    data: { headers?: any; body?: any }
  ): Observable<T> {
    switch (type) {
      case 'get':
        return this.http.get<T>(
          `${this._HTTP_URL}/${endpoint}`,
          data.headers ? { headers: new HttpHeaders(data.headers) } : undefined
        );
      case 'delete':
        return this.http.delete<T>(
          `${this._HTTP_URL}/${endpoint}`,
          data.headers ? { headers: new HttpHeaders(data.headers) } : undefined
        );
      case 'put':
      case 'post':
        data.headers = {
          ...data.headers,
          'Content-Type': 'application/json'
        };
        return this.http[type]<T>(
          `${this._HTTP_URL}/${endpoint}`,
          JSON.stringify(data.body),
          data.headers ? { headers: new HttpHeaders(data.headers) } : undefined
        );
      default:
        throw new Error(`Request type ${type} is not supportable`);
    }
  }
}
