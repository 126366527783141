export enum ItemStatus {
  Success = 'Success',
  Failure = 'Failure'
}

export interface ItemChangeData<DATA, ERROR> {
  data?: DATA;
  error?: ERROR;
  status: ItemStatus;
}
