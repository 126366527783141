import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PinnaklSpinnerComponent } from './global-spinner.component';

@NgModule({
  declarations: [PinnaklSpinnerComponent],
  imports: [CommonModule],
  exports: [PinnaklSpinnerComponent]
})
export class GlobalSpinnerModule { }
