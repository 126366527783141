import { Injectable } from '@angular/core';
import { Toastr } from './toastr.service';
import { PINNAKL_MESSAGES } from '@pinnakl/shared/constants';
import { FrontendErrorService } from './frontend-error.service';
import { PinnaklSpinnerService } from './spinner.service';

export type ClientError = { clientMessage: string } & Partial<Error>;

@Injectable({
  providedIn: 'root'
})
export class ShowErrorService {
  constructor(
    private readonly frontendErrorService: FrontendErrorService,
    private readonly pinnaklSpinner: PinnaklSpinnerService,
    private readonly toastr: Toastr
  ) {}

  showError(error: ClientError): void {
    this.pinnaklSpinner.stop();

    const defaultErrorMessage = PINNAKL_MESSAGES.SOMETHING_WENT_WRONG;
    if (!error) {
      this.toastr.error(defaultErrorMessage);
      return;
    }
    const { clientMessage } = error;
    const errorMessage = clientMessage ? clientMessage : defaultErrorMessage;
    this.toastr.error(errorMessage);
    this.frontendErrorService.handleError(error);
  }
}
