export interface SecurityFromApi {
  assettype: string;
  assettypeid: string;
  countryofincorporation: string;
  countryofrisk: string;
  countryofquotationid: string;
  countryofquotationcode: string;
  countryofquotationname: string;
  countryofincorporationcode: string;
  countryofincorporationname: string;
  currency: string;
  currencyid: string;
  cusip: string;
  datasource: string;
  description: string;
  id: string;
  isin: string;
  loanid: string;
  manualpricingindicator: string;
  moodyrating: string;
  multiplier: string;
  opracode: string;
  organization_id: string;
  organizationname: string;
  organization_status_descr: string;
  organization_status_id: string;
  organizationticker: string;
  privateindicator: string;
  sandprating: string;
  sector: string;
  sectype: string;
  sectypedescription: string;
  sectypeid: string;
  sedol: string;
  ticker: string;
  principal_factor: string;
  initialmargin: string;
  maintenancemargin: string;
  maturity: string;
  underlyingsecid: string;
  sharesoutstanding: string;
  pricingsourcename: string;
  securitypricingid: string;
  securitymarketid: string;
  marketid: string;
  securityid: string;
}
