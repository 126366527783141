import { Injectable } from '@angular/core';

// interface FrontEndError {
//   clientId?: number;
//   error: string;
//   errorDate: string;
//   route: string;
//   userToken?: string;
//   userId?: number;
// }

// const errorsToSkip = [
//   'invalid or expired authentication token',
//   'firebaseerror: messaging: notifications have been blocked',
//   'operation timed out',
//   'expressionchangedafterithasbeencheckederror'
// ]

@Injectable({
  providedIn: 'root'
})
export class FrontendErrorService {
  handleError(error: any): void {
    console.error(error);

    // TODO: skip for now until most errors will be fixed
    return;
    // const frontEndError = this._constructFrontEndError(error);
    // if (errorsToSkip.some(error => frontEndError.error.toLowerCase().includes(error))) {
    //   return;
    // }
    // this._handleFrontError(frontEndError);
  }

  // private _constructFrontEndError(error: any): FrontEndError {
  //   const errorString = this._getFormattedError(error);
  //   let frontEndError: FrontEndError = {
  //     error: errorString,
  //     errorDate: moment.utc().format('MM/DD/YYYY hh:mm:ss a'),
  //     route: location.hash
  //   };
  //
  //   const userInSession = localStorage.getItem('user');
  //   const user = userInSession ? JSON.parse(userInSession) : null;
  //   if (user) {
  //     frontEndError = {
  //       ...frontEndError,
  //       clientId: user.clientId,
  //       userToken: user.token,
  //       userId: user.id
  //     };
  //   }
  //   return frontEndError;
  // }

  // private _getFormattedError(error: any): string {
  //   if (error instanceof Error) {
  //     const { message, stack } = error;
  //     return JSON.stringify({ message, stack });
  //   }
  //   try {
  //     return JSON.stringify(error);
  //   } catch {
  //     console.error('Can\'t stringify an error')
  //   }
  //   try {
  //     const errorFormatted = Object.getOwnPropertyNames(error)
  //       .filter(propertyName => {
  //         try {
  //           error[propertyName].toString();
  //           return true;
  //         } catch {
  //           return false;
  //         }
  //       })
  //       .map(propertyName => ({
  //         [propertyName]: error[propertyName].toString()
  //       }))
  //       .reduce(
  //         (formattedResult, propertyNameWithValue) => ({
  //           ...formattedResult,
  //           ...propertyNameWithValue
  //         }),
  //         {}
  //       );
  //     return JSON.stringify(errorFormatted);
  //   } catch {
  //     console.error('Can\'t stringify an error')
  //   }
  //   return JSON.stringify({ message: 'Unable to parse error' });
  // }
  //
  // private _handleFrontError(frontEndError: FrontEndError): void {
  //   this._http
  //     .post<any>(this.FRONT_END_ERROR_RESOURCE_URL, frontEndError)
  //     .toPromise()
  //     .catch(reason => {
  //       console.error('[FrontEndErrorService] POST failed, reason:', reason);
  //     });
  // }
}
