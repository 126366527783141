import { isDevMode, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { clearStore } from './clear-store';
import * as fromRouterState from './router';
import * as _ from 'lodash';

export interface State {
  router: fromRouterState.RouterState;
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    if (!action.type.includes('@ngrx') && isDevMode()) {
      // console.log('[APP STATE] [Whole state]', _.cloneDeep(state));
      // console.log(`[APP STATE] [${action.type}]`, _.cloneDeep(action));
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [
  debug,
  clearStore,
];

export const reducers: ActionReducerMap<State> = {
  router: fromRouterState.reducer
};

@NgModule({
  imports: [
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' })
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: fromRouterState.CustomRouterStateSerializer
    }
  ]
})
export class AppStateModule {}
