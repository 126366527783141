export class TradeWorkflowSpecs {
  constructor(
    public id: number,
    public calcAum: boolean,
    public manualApproval: boolean,
    public nonListedFills: boolean,
    public locatesIntegration: boolean,
    public minIncrement: boolean,
    public quantityAsPct: number,
    public onlyViewTodaysTradesInOMS: boolean,
    public defaultAllocationAccts: string,
    public preTradeAllocationsRequired: boolean,
    public riskModuleEnabled: boolean,
    public realTimePortfolio: boolean,
    public rebalanceBpsAdjVisible: boolean,
    public intradayGLGrouping: IntradayGLGrouping,
    public clientId?: string,
  ) {}
}

export class TradeWorkflowSpecsFromApi {
  constructor(
    public id: string,
    public calcaum: string,
    public manualapproval: string,
    public locatesintegration: string,
    public nonlistedfills: string,
    public minincrement: string,
    public quantityaspct: string,
    public onlyviewtodaystradesinoms: string,
    public defaultallocationaccts: string,
    public pretradeallocationsrequired: string,
    public riskmoduleenabled: string,
    public realtimeportfolio: string,
    public rebalancebpsadjvisible: string,
    public intradayglgrouping: string,
    public clientid?: string
  ) {}
}

// eslint-disable-next-line no-shadow
export enum IntradayGLGrouping {
  SECURITY = 'SECURITY',
  ORGTICKER = 'ORGTICKER',
  FOLDER = 'FOLDER'
}

