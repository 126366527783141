export interface Account {
  accountCode: string;
  clientId: number;
  id: number;
  isPrimaryForReturns: boolean;
  name: string;
  orderOfImportance: string;
  pbAccounts: PBAccount[];
}

export interface PBAccount {
  id: number;
  accountCode: string;
  accountId: number;
  accountType: string;
  custodianName: string;
  custodianId: number;
  custodianCode: string;
  custodianAccountNumber: string;
  recon: boolean;
  subAccounts: PBSubAccount[];
}
export interface PBSubAccount {
  id: number;
  pbAccountId: number;
  code: string;
}
