import { fileServiceUrls, vdrFileAppHosts } from './pinnakl-environment-options';
import { EnvNames, PinnaklEnvironment } from '@pinnakl/core/environment';
import { AppNames } from '@pinnakl/shared/types';

const envName = EnvNames.dev;

export const environment: PinnaklEnvironment = {
  envName,
  production: false,
  appName: AppNames.CRM_DOCUMENTS,
  vdrFileAppHost: vdrFileAppHosts[envName],
  fileServiceUrl: fileServiceUrls[envName],
};
