import { Holiday } from '@pinnakl/shared/types';
import * as _ from 'lodash';
import * as moment from 'moment/moment';

export const DateHelpers = {
  getPreviousBusinessDay: (initialDate?: Date): Date => {
    const dayInMs = 24 * 60 * 60 * 1000;
    let lastWorkDay = new Date((initialDate ? initialDate.getTime() : Date.now()) - dayInMs);
    while ([0, 6].includes(lastWorkDay.getDay())) {
      lastWorkDay = new Date((lastWorkDay as any) - dayInMs);
    }
    return lastWorkDay;
  },
  getCurrentFirstDayOf: (value: 'month' | 'quarter' | 'year'): Date => {
    return moment(new Date()).startOf(value).toDate();
  },
  compareDatesWithoutTime: (date1: Date, date2: Date): number => {
    if (!date1 || !date2) {
      throw 'You need provide proper date values';
    }
    const dateA = new Date(date1.getTime());
    dateA.setHours(0, 0, 0, 0);
    const dateB = new Date(date2.getTime());
    dateB.setHours(0, 0, 0, 0);
    return dateA.getTime() - dateB.getTime();
  },
  isDateInBetween: (currentDate: Date, startDate: Date, endDate: Date): boolean => {
    return (
      (!startDate || DateHelpers.compareDatesWithoutTime(currentDate, startDate) >= 0) &&
      (!endDate || DateHelpers.compareDatesWithoutTime(endDate, currentDate) >= 0)
    );
  },
  compareDates: (date1: Date, date2: Date): boolean => {
    if (date1) {
      if (date2) {
        return DateHelpers.compareDatesWithoutTime(date1, date2) === 0;
      } else {
        return false;
      }
    } else {
      return !date2;
    }
  },
  addWeekdays: (date: Date, days: number): Date => {
    let dateMoment = moment(date);
    while (days !== 0) {
      dateMoment = days > 0 ? dateMoment.add(1, 'days') : dateMoment.subtract(1, 'days');
      if (dateMoment.isoWeekday() !== 6 && dateMoment.isoWeekday() !== 7) {
        const increment = days > 0 ? -1 : 1;
        days += increment;
      }
    }
    return dateMoment.toDate();
  },
  addBusinessDays: (date: Date, days: number, holidays: Holiday[]): Date => {
    let dateMoment = moment(date); // use a clone

    const momentHolidaysArray = _.map(holidays, 'holidayDate').map(o =>
      moment(o).format('MM/DD/YYYY')
    );

    while (days !== 0) {
      dateMoment = days > 0 ? dateMoment.add(1, 'days') : dateMoment.subtract(1, 'days');
      if (
        dateMoment.isoWeekday() !== 6 &&
        dateMoment.isoWeekday() !== 7 &&
        momentHolidaysArray.indexOf(dateMoment.format('MM/DD/YYYY')) === -1
      ) {
        const increment = days > 0 ? -1 : 1;
        days += increment;
      }
    }
    return dateMoment.toDate();
  },
  getDate: (value: string, format: string): Date | null => {
    const dateMoment = moment(value, format);
    return dateMoment.isValid() ? dateMoment.toDate() : null;
  },
  getUtcDate: (value: string, format: string) => {
    return moment.utc(value, format).toDate();
  },
  getDateTime: (value: string, format: string): number | null => {
    return DateHelpers.getDate(value, format)?.getDate() ?? null;
  }
};
