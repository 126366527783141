import { Injectable } from '@angular/core';
import {
  PartnershipAccountingDataUploadStatus,
  PartnershipAccountingDataUploadStatusApi,
} from '@pinnakl/shared/types';
import { WebServiceProvider } from '@pinnakl/core/web-services';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvestorPortalService {
  private readonly _crmMonthlyDataloadStatusEndpoint =
    'entities/crm_partnership_accounting_dataload_status';

  constructor(private wsp: WebServiceProvider) {}

  async getMonthlyDataloadStatus(
    startDate: Date,
    endDate: Date
  ): Promise<PartnershipAccountingDataUploadStatus[]> {
    const reqStartDate = moment(startDate).format('MM/DD/YYYY');
    const reqEndDate = moment(endDate).format('MM/DD/YYYY');

    const entities = await this.wsp.getHttp<PartnershipAccountingDataUploadStatusApi[]>({
      endpoint: this._crmMonthlyDataloadStatusEndpoint,
      params: {
        filters: [
          {
            key: 'startDate',
            type: 'EQ',
            value: [reqStartDate]
          },
          {
            key: 'endDate',
            type: 'EQ',
            value: [reqEndDate]
          }
        ]
      }
    });
    return entities.map(this.formatPartnershipAccountingDataUploadStatus);
  }

  public formatPartnershipAccountingDataUploadStatus(
    partnershipAccountingData: PartnershipAccountingDataUploadStatusApi
  ): PartnershipAccountingDataUploadStatus {
    return new PartnershipAccountingDataUploadStatus(
      +partnershipAccountingData.bouncedcount,
      +partnershipAccountingData.campaignscount,
      !!+partnershipAccountingData.datauploaded,
      new Date(partnershipAccountingData.monthenddate),
      +partnershipAccountingData.openedcount,
      +partnershipAccountingData.sentcount,
      !!+partnershipAccountingData.testingstatus
    );
  }
}
