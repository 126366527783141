import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import { EnvNames } from './environment-names.enum';

export interface PinnaklEnvironment {
  envName: EnvNames;
  appName: string;
  fileServiceUrl?: string;
  firebaseConfig?: {
    messagingSenderId?: string;
    apiKey?: string;
    projectId?: string;
    appId?: string;
  };
  includeTesting?: boolean;
  frontEndErrorServiceUrl?: string;
  production?: boolean;
  requestTimeoutPeriod?: number;
  coreApiServerUrl?: string;
  tradingServiceUrl?: string;
  httpServerUrl?: string;
  datastream1Url?: string;
  datastream2Url?: string;
  authConfig?: OpenIdConfiguration;
  vdrFileAppHost?: string;
}
