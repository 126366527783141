export interface TradeAllocation {
  id?: number;
  tradeId?: number;
  pbAccountId?: number;
  accountType?: string;
  accountId?: number;
  accountCode?: string;
  custodianId?: number;
  custodianCode?: string;
  subAccountId?: number;
  subAccountCode?: string;
  quantity?: number;
}
