import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';
import * as moment from 'moment/moment';
import { Store } from '@ngrx/store';
import {
  AuthStateResult,
  EventTypes,
  OidcSecurityService,
  PublicEventsService,
  ValidationResult
} from 'angular-auth-oidc-client';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ClearStore } from '@pinnakl/app-state';
import { PinnaklFingerprintService, PinnaklSpinnerService, Toastr } from '@pinnakl/shared/util-providers';
import {
  AppNames,
  IUser,
  SessionInformation,
  SessionInformationFromApi,
  TwoFactorType,
  UserLoginFromApi,
  UserTypes
} from '@pinnakl/shared/types';
import { AuthSessionInfo, DEFAULTSCREEN, USERTYPE } from './models';
import { UserService } from './user.service';
import { ILogService, LOGGER } from '@pinnakl/log';
import { getAppInstanceToken } from '@pinnakl/shared/util-helpers';

declare const require: any;
const packageJson = require('../../../../../package.json');

export interface Credentials {
  username: string;
  password: string;
  otp?: string;
}

interface Claim {
  sub: string;
  oi_au_id: string;
  azp: string;
  nonce: string;
  at_hash: string;
  oi_tkn_id: string;
  aud: string;
  exp: number;
  iss: string;
  iat: number;
}

const CREDENTIALS_TEMP_KEY = 'credentials';
const DEFAULT_SESSION = {
  visible: false,
  activeSessions: []
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  loginCredentials$: BehaviorSubject<Credentials | null> = new BehaviorSubject<Credentials | null>(
    null
  );
  sessionsInformation$: BehaviorSubject<AuthSessionInfo> = new BehaviorSubject<AuthSessionInfo>(
    DEFAULT_SESSION
  );
  refreshingAuthState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userData$: BehaviorSubject<Claim | null> = new BehaviorSubject<Claim | null>(null);
  version$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  errorMessage$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  otpSecret$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  isAuthenticated$: Observable<boolean> = this.oidcSecurityService.isAuthenticated$.pipe(
    switchMap(result => {
      const isAuthenticated = !!result?.isAuthenticated;
      return isAuthenticated ? this.userService.userAvailable$.asObservable() : of(isAuthenticated);
    })
  );
  baseUrl?: string;
  version?: string;
  application = 'Desktop';
  loggingOff = false;
  private ROUTE_AFTER_LOGIN = '/';
  productionMode = false;
  appName: AppNames;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(DEFAULTSCREEN) readonly defaultScreen: { prod: string; dev: string },
    @Inject(USERTYPE) readonly userType: UserTypes,
    @Inject(ENVIRONMENT_SERVICE) readonly environmentService: EnvironmentService,
    private readonly router: Router,
    private readonly toast: Toastr,
    private readonly spinner: PinnaklSpinnerService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly eventService: PublicEventsService,
    private readonly userService: UserService,
    private readonly http: HttpClient,
    private readonly userFingerprintService: PinnaklFingerprintService,
    private readonly store: Store<any>,
    @Inject(LOGGER) private readonly logger: ILogService,
  ) {
    this.productionMode = this.environmentService.get('production');
    this.baseUrl = this.environmentService.get('authConfig')?.authority;
    this.appName = this.environmentService.get('appName');
    if (this.appName !== AppNames.CRM_INVESTOR_PORTAL) {
      this.checkAppVersion();
    }
    this.version$.next(packageJson.version);
    this.ROUTE_AFTER_LOGIN = this.productionMode ? this.defaultScreen.prod : this.defaultScreen.dev;
    console.log('Login page: PROD:', this.productionMode);
    console.log('Login page: DEFAULTSCREEN:', this.ROUTE_AFTER_LOGIN);
    window.onfocus = () => this.checkUserSession();
  }

  get getSearchParams() {
    return this.document?.location?.search ?? '';
  }

  get fingerprint() {
    return this.userFingerprintService?.fingerprint?.visitorId ?? '';
  }

  get configId() {
    const authConfig = this.environmentService.get('authConfig');
    return getAppInstanceToken(authConfig?.configId, location?.host, this.appName);
  }

  get authUrls(): Record<'login' | 'magicLink' | 'regenerateMagicLink' |'verifyotp' | 'forgotPassword', string> {
    return {
      login: `${this.baseUrl}/account/login`,
      magicLink: `${this.baseUrl}/account/link/valid`,
      regenerateMagicLink: `${this.baseUrl}/account/link`,
      verifyotp: `${this.baseUrl}/account/verifyotp`,
      forgotPassword: `${this.baseUrl}/forgotpassword`
    };
  }

  get authWellKnownEndPoints(): Record<string, string> {
    try {
      const authData = localStorage.getItem(this.configId);
      const authDataParsed = JSON.parse(authData ?? '{}');
      const endpoints = authDataParsed?.authWellKnownEndPoints ?? {};
      const { issuer, ...rest } = endpoints;
      return { ...rest, ...this.authUrls };
    } catch (e) {
      return {};
    }
  }

  // Should be initialized inside base app.component constructor
  init() {
    this.logger.post({
      action: 'AuthService:init'
    });
    combineLatest([
      this.oidcSecurityService.isAuthenticated(),
      this.oidcSecurityService.getState()
    ]).subscribe(([isAuthenticated, state]) => {
      const { href, search } = this.document.location;
      const hasReturnUrl = href.includes('returnUrl');
      const hasCode = href.includes('code');
      if (hasReturnUrl) {
        const params = new URLSearchParams(search);
        const returnUrl = params.get('returnUrl') ?? '';
        const returnUrlParams = new URLSearchParams(returnUrl);
        const returnUrlState = returnUrlParams.get('state') ?? '';
        if (state !== returnUrlState) {
          this.startAuth();
        }
      }
      if (!isAuthenticated && !(hasReturnUrl || hasCode)) {
        this.userService.removeUser();
        this.startAuth();
      }
      if ((!isAuthenticated && hasCode && !href.includes('2fa')) || isAuthenticated) {
        this.checkAuthAndGetUser();
      }
      if (!this.loginCredentials$.value) {
        this.getTempCredentials();
      }
    });
    this.listenToAccessTokenRenew();
  }

  // withCredentials required so that Angular returns the Cookies received from the server.
  // The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
  login(login: Credentials): void {
    if (this.baseUrl) {
      this.getAuthState().subscribe(state => {
        if (state) {
          this.spinner.spin();
          const user = {
            ...login,
            application: this.application,
            userType: this.userType,
            fingerprint: this.fingerprint
          };
          const loginUrl = this.authUrls.login + this.getSearchParams;
          this.http
            .post<{ returnUrl: string }>(loginUrl, user, { withCredentials: true })
            .pipe(
              take(1),
              catchError(e => {
                this.handleAuthError(e, login);
                return EMPTY;
              }),
              finalize(() => this.spinner.stop())
            )
            .subscribe((res: { returnUrl: string } | null) => {
              this.setTempCredentials(login);
              if (res?.returnUrl) {
                window.location.href = res.returnUrl;
              } else {
                this.startAuth();
              }
            });
        }
      });
    }
  }

  regenarateMagicLink(email, callback: () => void) {
    this.spinner.spin();
    const endpoint = `${this.authUrls.regenerateMagicLink}`;
    this.http.post<any>(endpoint, { email }).pipe(
      take(1),
      catchError(e => this.handleApiError(e, 'Error while regenerating magic link'))
    ).subscribe(() => {
      this.spinner.stop();
      this.toast.success(`A new authentication link has been sent to ${email}`);
      callback?.();
    });
  }

  forgotPassword(email, callback: () => void) {
    this.spinner.spin();
    const endpoint = `${this.authUrls.forgotPassword}${this.getSearchParams}&email=${email}&usertype=${this.userType}`;
    this.http
      .get<any>(endpoint)
      .pipe(
        take(1),
        catchError(e => this.handleApiError(e, 'Error while forgot password'))
      )
      .subscribe(() => {
        this.spinner.stop();
        this.toast.success(`A new temporary password has been sent to ${email}`);
        this.startAuth();
        callback?.();
      });
  }

  verifyOtp(otp): void {
    if (this.baseUrl) {
      this.spinner.spin();
      this.getAuthState().subscribe(state => {
        if (state) {
          // IRL we need returnUrl and otp parameters only
          const verifyUrl = `${this.authUrls.verifyotp}${this.getSearchParams}&otp=${otp}`;
          this.http
            .get<{ returnUrl: string }>(verifyUrl, {
              withCredentials: true
            })
            .pipe(
              take(1),
              catchError(e => this.handleApiError(e, 'Error while OTP verification'))
            )
            .subscribe((res: { returnUrl: string } | null) => {
              this.spinner.stop();
              window.location.href = res?.returnUrl
                ? this.baseUrl + res?.returnUrl
                : `${window.location.origin}/${this.ROUTE_AFTER_LOGIN}`;
            });
        }
      });
    }
  }

  localLogout() {
    this.logout(true);
  }

  logout(local = false): void {
    this.logger.post({
      action: `AuthService:logout:local=${local}`
    });
    this.refreshingAuthState
      .asObservable()
      .pipe(take(1))
      .subscribe(isRefreshing => {
        if (!isRefreshing && !this.loggingOff) {
          this.loggingOff = true;
          this.spinner.spin();
          const user = this.userService.getUser();
          user?.firstName && localStorage.setItem('name', user?.firstName);
          this.router.navigate(['/login']).then(() => {
            this.userService.removeUser();
            this.store.dispatch(ClearStore());
            this.spinner.stop();
            if (local) {
              this.oidcSecurityService.logoffLocal();
              this.oidcSecurityService.authorize();
            } else {
              this.oidcSecurityService
                .logoff(this.configId, {
                  customParams: {
                    redirect_uri: window.location.origin
                  }
                })
                .subscribe();
            }
          });
        }
      });
  }

  getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  revokeRefreshToken(): void {
    this.oidcSecurityService.revokeRefreshToken().pipe(take(1)).subscribe();
  }

  revokeAccessToken(): void {
    this.oidcSecurityService.revokeAccessToken().pipe(take(1)).subscribe();
  }

  updateQrSecret(callback: () => void): void {
    const httpOptions: Record<string, any> = {
      headers: new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8'),
      responseType: 'text' as const,
      withCredentials: true
    };
    this.http
      .get<string>(`${this.baseUrl}/account/secret`, httpOptions)
      .pipe(
        take(1),
        tap(otpSecret => {
          if (otpSecret) {
            this.otpSecret$.next(otpSecret.replace(/^"|"$/g, ''));
            callback?.();
          }
        })
      )
      .subscribe();
  }

  getTempCredentials(): Credentials | null {
    try {
      const savedCredentials = localStorage.getItem(CREDENTIALS_TEMP_KEY);
      if (savedCredentials) {
        const credentials = JSON.parse(savedCredentials ?? '{}');
        credentials && this.loginCredentials$.next(credentials);
        return credentials;
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  deactivateAllSessions(activeSessions: SessionInformation[], callback: () => void): void {
    this.spinner.spin();
    forkJoin(activeSessions.map(as => this.deAuthenticate(as.userToken)))
      .pipe(
        take(1),
        catchError(e => this.handleApiError(e, 'Failed to deactivate this session'))
      )
      .subscribe(res => {
        if (res) {
          this.sessionsInformation$.next(DEFAULT_SESSION);
          callback();
        }
        this.spinner.stop();
      });
  }

  private startAuth(): void {
    this.logger.post({
      action: `AuthService:startAuth:config=${JSON.stringify(localStorage.getItem(this.configId))}`
    });
    this.oidcSecurityService.authorize();
  }

  private checkAuthAndGetUser(withLoading = true) {
    this.logger.post({
      action: `AuthService:checkAuthAndGetUser:config=${JSON.stringify(localStorage.getItem(this.configId))}`
    });
    withLoading && this.spinner.spin();
    this.refreshingAuthState.next(true);
    this.oidcSecurityService.checkAuth().pipe(
      take(1),
      switchMap((res: { accessToken: string; userData: Claim | null } | null) => {
        this.logger.post({
          action: `AuthService:checkAuthAndGetUser:checkAuth:switchMap=${JSON.stringify(res)}`
        });
        if (res?.userData) {
          this.userData$.next(res.userData);
        }
        return res?.accessToken ? this.getUserInfo(res?.accessToken) : of(null);
      })
    ).subscribe((response: UserLoginFromApi | null) => {
      this.logger.post({
        action: `AuthService:checkAuthAndGetUser:checkAuth:subscribe=${JSON.stringify(response)}`
      });
      if (response) {
        const userToSave: IUser = this.userService.formatLoginUser({
          ...response,
          username: this.loginCredentials$.value?.username ?? this.userData$.value?.sub ?? ''
        });
        response?.user.otpSecret &&
          response?.user.otpChannel === TwoFactorType.QR &&
          this.otpSecret$.next(response?.user.otpSecret);
        this.userService.setUser(userToSave);
        this.refreshingAuthState.next(false);
        withLoading && this.navigateAsAuthUser();
        return;
      }
      this.refreshingAuthState.next(false);
      withLoading && this.spinner.stop();
    });
  }

  passwordResetDone() {
    this.checkAuthAndGetUser();
  }

  navigateAsAuthUser(forceRedirect = false) {
    this.logger.post({
      action: 'AuthService:navigateAsAuthUser'
    });
    const { pathname } = this.document.location;
    // Navigate user to default route after login only if he is at /login or /2fa routes
    const userHasAuthRoute =
      pathname !== '/' && ['/login', '/2fa'].every(path => !pathname.startsWith(path));
    if (userHasAuthRoute && !forceRedirect) {
      this.spinner.stop();
      return;
    }
    this.router.navigate([this.ROUTE_AFTER_LOGIN]).then(() => {
      this.deleteTempCredentials();
      this.spinner.stop();
    });
  }

  magicLinkNavigated(token: string) {
    this.logger.post({
      action: `AuthService:magicLinkNavigated:parsedToken=${window.atob(token.split('.')[1])}`
    });
    const isLatestAppVersion = this.isLatestAppVersion();
    if (!isLatestAppVersion) {
      this.dropCachedData();
      this.setCurrentAppVersion();
      window.location.assign(window.location.href);
    }

    const user = this.userService.getUser();
    this.logger.post({
      action: `AuthService:magicLinkNavigated:userIsPresent=${!!user}`
    });
    if (user) {
      this.checkUserSession();
    } else {
      this.dropCachedData();
      this.magicLinkAuth(token);
    }
  }

  private magicLinkAuth(token: string): void {
    this.logger.post({
      action: `AuthService:magicLinkAuth`
    });
    const url = this.authUrls.magicLink + `?token=${token}`;
    this.http.get(url, { withCredentials: true }).subscribe({
      next: () => {
        this.logger.post({
          action: `AuthService:magicLinkAuth:success`
        });
        this.startAuth()
      },
      error: (httpError) => {
        this.logger.post({
          action: `AuthService:magicLinkAuth:error=${httpError}`
        });
        if (httpError?.error?.passwordResetRequired) {
          return this.router.navigateByUrl('/login?regenerateMagicLink=true');
        } else {
          this.startAuth();
        }
      }
    });
  }

  private isLatestAppVersion(): boolean {
    const appVersion = localStorage.getItem('appVersion');
    this.logger.post({
      action: `AuthService:isLatestAppVersion:appVersion=${appVersion}`
    });
    if (!appVersion) return false;
    return packageJson.version === appVersion;
  }

  private dropCachedData() {
    this.logger.post({
      action: `AuthService:dropCachedData`
    });
    this.oidcSecurityService.logoffLocal(this.configId);
    this.userService.removeUser();
  }

  private setCurrentAppVersion() {
    localStorage.setItem('appVersion', packageJson.version);
  }

  private checkAppVersion() {
    if (!this.isLatestAppVersion()) {
      localStorage.removeItem(this.configId);
      this.localLogout();
    }
    this.setCurrentAppVersion();
  }

  private getAuthState(): Observable<string> {
    return this.oidcSecurityService.getState().pipe(
      take(1),
      tap(state => !state && this.handleNoAuthStateError())
    );
  }

  private handleNoAuthStateError() {
    console.log('ERROR: No state auth found, re-authenticating');
    this.toast.error('Please restart authentication');
    this.oidcSecurityService.authorize();
  }

  private deAuthenticate(sessionToken: string) {
    return this.http.delete('/auth', {
      withCredentials: true,
      headers: { Authorization: `Bearer ${sessionToken}` }
    });
  }

  private checkUserSession() {
    this.logger.post({
      action: `AuthService:checkUserSession`
    });
    const { pathname } = this.document.location;
    const isLoginPage = ['/login'].includes(pathname);
    const is2Fa = ['/2fa'].includes(pathname);
    !is2Fa &&
      this.oidcSecurityService
        .checkAuth()
        .pipe(take(1))
        .subscribe(result => {
          const isAuthenticated = !!result?.isAuthenticated;
          const user = this.userService.getUser();
          if (!isLoginPage && (!isAuthenticated || !user)) {
            this.localLogout();
          }
          if (isLoginPage && (isAuthenticated || user)) {
            this.checkAuthAndGetUser(false);
          }
        });
  }

  private listenToAccessTokenRenew() {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => notification.type === EventTypes.NewAuthenticationResult),
        map(result => {
          const {
            value: { isAuthenticated, validationResult }
          } = result as { value: AuthStateResult };
          return isAuthenticated && validationResult === ValidationResult.Ok;
        }),
        switchMap(isAuthenticated => {
          !isAuthenticated && this.refreshingAuthState.next(!isAuthenticated);
          return !isAuthenticated
            ? this.oidcSecurityService.forceRefreshSession()
            : of({ isAuthenticated });
        })
      )
      .subscribe(result => {
        const isAuthenticated = !!result?.isAuthenticated;
        if (isAuthenticated) {
          !this.refreshingAuthState.getValue() && this.checkAuthAndGetUser(false);
        } else {
          this.localLogout();
        }
        this.refreshingAuthState.next(!isAuthenticated);
      });
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification =>
          [EventTypes.TokenExpired, EventTypes.IdTokenExpired].includes(notification.type)
        )
      )
      .subscribe(() => this.refreshingAuthState.next(true));
  }

  private getUserInfo(accessToken: string): Observable<UserLoginFromApi | null> {
    this.logger.post({
      action: `AuthService:getUserInfo`
    });
    return this.http
      .get<UserLoginFromApi>(`${this.baseUrl}/connect/userinfo`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          if (e.status === 401) {
            this.localLogout();
          }
          return of(null);
        })
      );
  }

  private setTempCredentials(data: Credentials) {
    this.loginCredentials$.next(data);
    localStorage.setItem(CREDENTIALS_TEMP_KEY, JSON.stringify(data));
  }

  private deleteTempCredentials() {
    this.loginCredentials$.next(null);
    localStorage.removeItem(CREDENTIALS_TEMP_KEY);
  }

  private handleApiError(e, toastMessage: string): Observable<any> {
    this.spinner.stop();
    this.toast.error(toastMessage);
    return throwError(() => e);
  }

  private handleAuthError(e: any, login: Credentials) {
    if (e?.status === 401 || e?.status === 403) {
      this.errorMessage$.next('Email/Password combination is incorrect!');
    }
    if (e?.error?.data?.otp === false) {
      const { username, password } = login;
      this.setTempCredentials({ username, password });
      this.router.navigate(['/2fa'], { queryParamsHandling: 'preserve' });
    } else if (e.message) {
      console.log(e.message);
      const { activeSessions } = this.handleSessionsError(e);
      if (activeSessions && activeSessions.length) {
        this.sessionsInformation$.next({ activeSessions, visible: true });
      }
    }
  }

  private handleSessionsError(error): { message: string; activeSessions: SessionInformation[] } {
    const { message: errorMessage } = error;
    if (!errorMessage.toLowerCase().includes('too many logins')) {
      throw new Error(errorMessage);
    }
    const activeSessionsStringStartingIndex = errorMessage.indexOf('[');
    const activeSessionsStringEndingIndex = errorMessage.lastIndexOf(']') + 1;
    const activeSessionsString = errorMessage.slice(
      activeSessionsStringStartingIndex,
      activeSessionsStringEndingIndex
    );
    let activeSessions: SessionInformation[] = [];
    try {
      const activeSessionsFromApi: SessionInformationFromApi[] = JSON.parse(activeSessionsString);
      activeSessions = activeSessionsFromApi.map(this.formatSessionInformation);
    } catch (err) {
      console.error('Error:', err);
    }
    return {
      message: 'Too many logins',
      activeSessions
    };
  }

  private formatSessionInformation(entity: SessionInformationFromApi): SessionInformation {
    const createdBy = parseInt(entity.createdby),
      createdDateMoment = moment.utc(entity.createddate, 'MM/DD/YYYY hh:mm:ss a'),
      id = parseInt(entity.id),
      updatedBy = parseInt(entity.updatedby),
      updatedDateMoment = moment.utc(entity.updateddate, 'MM/DD/YYYY hh:mm:ss a'),
      userId = parseInt(entity.userid);
    return {
      active: entity.active === 'True',
      browser: entity.browser,
      city: entity.city,
      country: entity.country,
      createdBy: !isNaN(createdBy) ? createdBy : null,
      createdDate: createdDateMoment.isValid() ? createdDateMoment.toDate() : null,
      deviceDetail: entity.devicedetail,
      fingerprint: entity.fingerprint,
      id: !isNaN(id) ? id : null,
      ipAddress: entity.ipaddress,
      language: entity.language,
      mobileToken: entity.mobiletoken,
      os: entity.os,
      screenresolution: entity.screenresolution,
      timezone: entity.timezone,
      updatedBy: !isNaN(updatedBy) ? updatedBy : null,
      updatedDate: updatedDateMoment.isValid() ? updatedDateMoment.toDate() : null,
      userId: !isNaN(userId) ? userId : null,
      userToken: entity.usertoken
    };
  }
}
