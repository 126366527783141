import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  LocalSpinnerSimpleModeContainerComponent
} from './local-spinner-simple-mode-container';
import { LocalSpinnerComponent } from './local-spinner';

@NgModule({
  exports: [
    LocalSpinnerComponent,
    LocalSpinnerSimpleModeContainerComponent
  ],
  imports: [
    CommonModule
  ],
  declarations: [
    LocalSpinnerComponent,
    LocalSpinnerSimpleModeContainerComponent
  ]
})
export class BaseComponentsModule {}
