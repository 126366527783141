import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable({ providedIn: 'root' })
export class PinnaklFingerprintService {
  private userFingerprint?: { visitorId: string };

  constructor() {
    FingerprintJS.load()
      .then(fp => fp.get())
      .then(data => (this.userFingerprint = data))
      .catch(e => console.log(e));
  }

  get fingerprint(): { visitorId: string } {
    return this.userFingerprint ? { ...this.userFingerprint } : { visitorId: '' };
  }
}
