import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { InlineSVGService } from './inline-svg.service';
import { InlineSVGDirective } from "./inline-svg.directive";

@Component({
  selector: 'inline-svg',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineSVGComponent implements AfterViewInit, OnChanges {
  @Input() context!: InlineSVGDirective;
  @Input() content!: HTMLElement | SVGElement;
  @Input() replaceContents!: boolean;
  @Input() prepend!: boolean;

  _el: ElementRef;

  constructor(private _inlineSVGService: InlineSVGService, el: ElementRef) {
    this._el = el;
  }

  ngAfterViewInit(): void {
    this._updateContent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['content']) {
      this._updateContent();
    }
  }

  private _updateContent(): void {
    this._inlineSVGService.insertEl(
      this.context,
      this._el.nativeElement,
      this.content,
      this.replaceContents,
      this.prepend
    );
  }
}
