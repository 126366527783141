/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { InlineSVGComponent } from './inline-svg.component';
import { InlineSVGService } from './inline-svg.service';
import { InlineSVGConfig } from './inline-svg.config';
import * as SvgUtil from './svg-util';
import { SVGCacheService } from './svg-cache.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[inlineSVG]',
  providers: [InlineSVGService, SVGCacheService]
})
export class InlineSVGDirective implements OnInit, OnChanges {
  @Input() inlineSVG!: string;
  @Input() resolveSVGUrl = true;
  @Input() replaceContents = true;
  @Input() prepend = false;
  @Input() injectComponent = false;
  @Input() cacheSVG = true;
  @Input() setSVGAttributes!: { [key: string]: any };
  @Input() removeSVGAttributes!: string[];
  @Input() forceEvalStyles = false;
  @Input() fallbackImgUrl!: string;
  @Input() SVGLoaded!: (svg: SVGElement, parent: Element | null) => SVGElement;
  @Output() SVGInserted: EventEmitter<SVGElement> = new EventEmitter<SVGElement>();
  @Output() SVGFailed: EventEmitter<any> = new EventEmitter<any>();

  _prevSVG!: SVGElement;
  private readonly _supportsSVG: boolean;
  private _prevUrl!: string;
  private _svgComp!: ComponentRef<any>;

  constructor(
    private _el: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private _renderer: Renderer2,
    private _inlineSVGService: InlineSVGService,
    private _svgCache: SVGCacheService,
    @Optional() private _config: InlineSVGConfig
  ) {
    this._supportsSVG = SvgUtil.isSvgSupported();

    if (!this._supportsSVG) {
      this._fail('Embed SVG are not supported by this browser');
    }
  }

  ngOnInit(): void {
    this._insertSVG();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const setSVGAttributesChanged = Boolean(changes['setSVGAttributes']);
    if (changes['inlineSVG'] || setSVGAttributesChanged) {
      this._insertSVG(setSVGAttributesChanged);
    }
  }

  private _insertSVG(force = false): void {
    if (!this._supportsSVG) {
      return;
    }

    if (!this.inlineSVG) {
      this._fail('No URL passed to [inlineSVG]');
      return;
    }

    if (!force && this.inlineSVG === this._prevUrl) {
      return;
    }
    this._prevUrl = this.inlineSVG;

    this._svgCache
      .getSVG(this.inlineSVG, this.resolveSVGUrl, this.cacheSVG)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (svg: SVGElement) => {
          if (SvgUtil.isUrlSymbol(this.inlineSVG)) {
            const symbolId = this.inlineSVG.split('#')[1];
            svg = SvgUtil.createSymbolSvg(this._renderer, svg, symbolId);
          }

          this._processSvg(svg);
        },
        error: (err: any) => {
          this._fail(err);
        }
      });
  }

  private _processSvg(svg: SVGElement) {
    if (!svg) {
      return;
    }

    if (this.removeSVGAttributes) {
      SvgUtil.removeAttributes(svg, this.removeSVGAttributes);
    }

    if (this.setSVGAttributes) {
      SvgUtil.setAttributes(svg, this.setSVGAttributes);
    }

    if (this.SVGLoaded) {
      svg = this.SVGLoaded(svg, this._el.nativeElement);
    }

    this._insertEl(svg);

    if (this.forceEvalStyles) {
      const styleTags = svg.querySelectorAll('style');
      Array.from(styleTags).forEach(tag => (tag.textContent += ''));
    }

    this.SVGInserted.emit(svg);
  }

  private _insertEl(el: HTMLElement | SVGElement): void {
    if (this.injectComponent) {
      if (!this._svgComp) {
        this._svgComp = this._viewContainerRef.createComponent(InlineSVGComponent);
      }

      this._svgComp.instance.context = this;
      this._svgComp.instance.replaceContents = this.replaceContents;
      this._svgComp.instance.prepend = this.prepend;
      this._svgComp.instance.content = el;

      this._renderer.appendChild(
        this._el.nativeElement,
        this._svgComp.injector.get(InlineSVGComponent)._el.nativeElement
      );
    } else {
      this._inlineSVGService.insertEl(
        this,
        this._el.nativeElement,
        el,
        this.replaceContents,
        this.prepend
      );
    }
  }

  private _fail(msg: string): void {
    this.SVGFailed.emit(msg);

    if (this.fallbackImgUrl) {
      const elImg = this._renderer.createElement('IMG');
      this._renderer.setAttribute(elImg, 'src', this.fallbackImgUrl);

      this._insertEl(elImg);
    }
  }
}
