import { Injectable } from '@angular/core';
import { PinnaklHttpService } from './pinnakl-http.service';
import {
  DeleteHttpRequest,
  GetHttpRequest,
  PostHttpRequest,
  PutHttpRequest
} from '@pinnakl/shared/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebServiceProvider {
  constructor(private readonly httpService: PinnaklHttpService) {}

  delete<T>(deleteHttpRequest: DeleteHttpRequest): Observable<T> {
    return this.httpService.deleteObservable<T>(deleteHttpRequest);
  }

  deleteHttp<T>(deleteHttpRequest: DeleteHttpRequest): Promise<T> {
    return this.httpService.delete<T>(deleteHttpRequest);
  }

  get<T>(getHttpRequest: GetHttpRequest): Observable<T> {
    return this.httpService.getObservable<T>(getHttpRequest);
  }

  getHttp<T>(getHttpRequest: GetHttpRequest): Promise<T> {
    return this.httpService.get<T>(getHttpRequest);
  }

  post<T>(postHttpRequest: PostHttpRequest): Observable<T> {
    return this.httpService.postObservable<T>(postHttpRequest);
  }

  postHttp<T>(postHttpRequest: PostHttpRequest): Promise<T> {
    return this.httpService.post<T>(postHttpRequest);
  }

  put<T>(putHttpRequest: PutHttpRequest): Observable<T> {
    return this.httpService.putObservable<T>(putHttpRequest);
  }

  putHttp<T>(putHttpRequest: PutHttpRequest): Promise<T> {
    return this.httpService.put<T>(putHttpRequest);
  }
}
