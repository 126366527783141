import { Validators } from '@angular/forms';

export const requiredIfValidator = (predicate) => {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  })
}
