export interface IReportColumnFromApiInterface {
  id: number;
  reportId: number;
  name: string;
  caption: string;
  type: string;
  viewOrder: number;
  sortAscending: boolean | null;
  sortOrder: number | null;
  filterValues: string[] | null;
  groupOrder: number | null;
  isAggregating: boolean | null;
  decimalPlaces: number| null;
  convertToBaseCurrency: boolean | null;
  renderingFunction: string | null;
  formula: string | null;
}

export interface ReportColumnFromApiInterface {
  caption: string;
  converttobasecurrency: string;
  decimalplaces: string;
  filtervalues: string;
  grouporder: string;
  id: string;
  isaggregating: string;
  name: string;
  renderingfunction: string;
  reportid: string;
  sortascending: string;
  sortorder: string;
  type: string;
  formula: string;
  vieworder: string;
}

export interface ClientReportColumnFromApiInterface {
  caption: string;
  clientreportid: string;
  decimalplaces: string;
  filtervalues: string;
  grouporder: string;
  id: string;
  isaggregating: string;
  name: string;
  reportcolumnid: string;
  sortascending: string;
  sortorder: string;
  type: string;
  vieworder: string;
}
