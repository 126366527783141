import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './not-found/not-found.component';
import { EnvironmentModule } from '@pinnakl/core/environment';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { GlobalSpinnerModule } from '@pinnakl/shared/base-components';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EnvironmentModule.forRoot({ config: environment }),
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    GlobalSpinnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
