export interface PartnershipAccountingDataUploadStatusApi {
  bouncedcount: string,
  campaignscount: string,
  datauploaded: string,
  monthenddate: string,
  openedcount: string,
  sentcount: string,
  testingstatus: string
}

export class PartnershipAccountingDataUploadStatus {
  constructor(
    public bouncedcount: number,
    public campaignscount: number,
    public datauploaded: boolean,
    public monthenddate: Date,
    public openedcount: number,
    public sentcount: number,
    public testingstatus: boolean){}
}
