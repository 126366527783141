export enum COMMISSION_TYPE {
  PERSHARE = 'PERSHARE',
  QUANTITY = 'QUANTITY',
  CPS = 'CPS',
  BPS = 'BPS',
  NET = 'NET'
}

export interface TradeCommission {
  commissionInput: number;
  totalCommission: number;
  commissionType: string;
  inHouseRate: number;
  destinationRate: number;
  destinationBrokerCode?: string;
}
