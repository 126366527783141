import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAttributesService } from './custom-attributes.service';
import { InvestorModule } from './investor';
import { MarksService } from './marks.service';
import { PresetsService } from './presets.service';
import { ReportingService } from './reporting.service';
import { UserService } from './user.service';
import { AuthService } from "./auth.service";

@NgModule({
  imports: [CommonModule, InvestorModule],
  providers: [
    AuthService,
    CustomAttributesService,
    MarksService,
    PresetsService,
    ReportingService,
    UserService
  ]
})
export class CoreDataProvidersModule {}
