import { Country } from '../country/country.model';
import { CurrencyForOms } from '../oms';
import { OrganizationV2 } from './organization.model';

export interface SecurityPricing {
  bloombergIdentifier: string;
  ticker: string;
  sedol: string;
}

export interface SecurityMarket2 {
  id: number;
  ticker: string;
  sedol: string;
  mic: string;
  countryOfQuotation: Country;
  currency: CurrencyForOms;
  securityPricing: SecurityPricing;
}
export interface SecurityV2 {
  id: number;
  assetType: string;
  assetTypeId: number;
  secType: string;
  secTypeId: number;
  cusip: string;
  isin: string;
  occSymbol: string;
  description: string;
  sector: string;
  initialMargin: number;
  multiplier: number;
  principalFactor: number;
  primaryCurrency: CurrencyForOms;
  coupon: number;
  dividend: number;
  private: boolean;
  market: SecurityMarket2;
  organization: OrganizationV2;
}
