export class ReportingColumn {
  public caption: string;
  public convertToBaseCurrency: boolean;
  public dbId: number;
  public decimalPlaces: number;
  public filters: number | Date | string[];
  public groupOrder: number;
  public include: boolean;
  public isAggregating: boolean;
  public name: string;
  public renderingFunction: string;
  public reportingColumnType: 'ca' | 'idc' | 'report';
  public sortAscending: boolean;
  public type: string;
  public formula: string;

  // Its not clear what the purpose of sort Order and view order is. I think we started we started
  // doing a new features and may be stopped half way. Backend does not even support accept view order property.
  // I think sort order is confusing and view order
  // Or may be the idea was to specify sort order as asc/desc as opposed to setting sortAscending property which is
  // not clear on how its sets
  public sortOrder: number;
  public viewOrder: number;
}
