import { Injectable } from "@angular/core";
import { PinnaklEnvironment } from './environment.model';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private _environment?: PinnaklEnvironment;

  get environment() {
    return this._environment
  }

  registerEnvironment(config: PinnaklEnvironment) {
    this._environment = config;
  }

  get(key: keyof PinnaklEnvironment): any {
    if (!this.environment) {
      throw new Error('Environment was not defined.');
    }
    if (this.environment[key] == null) {
      console.error('Requested property does not not exist in environment configuration.');
    }
    return this.environment[key];
  }
}
