import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pinnakl-local-spinner-simple-mode-container',
  templateUrl: 'local-spinner-simple-mode-container.component.html',
  styleUrls: ['local-spinner-simple-mode-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalSpinnerSimpleModeContainerComponent {
  @Input() size?: string;
  @Input() backgroundColor?: string;
  @Input() containerWidth = '100%';
  @Input() containerHeight = '150px';
  @Input() containerNgStyle: Record<string, string> = { };

  get combinedContainerNgStyle(): Record<string, string> {
    return {
      width: this.containerWidth,
      height: this.containerHeight,
      ...this.containerNgStyle,
    }
  }
}
