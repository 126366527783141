import {
  ClientReportColumnFromApiInterface,
  ClientReportColumnInterface,
  ReportColumnFromApiInterface,
  ReportColumnInterface
} from '@pinnakl/shared/types';
import * as moment from 'moment';
import { stringBooleanToBoolean, stringBooleanToBooleanOrNull, stringToIntegerNumberOrNull } from './types.helpers';

export const convertStringFilterValuesToStrictTyped = (
  value: string, type: string
): Date | number | string[] | null => {
  if (!type) {
    return null;
  }
  if (type === 'numeric') {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) ? parsedValue : null;
  } else {
    if (!value) {
      return null;
    }
    if (type === 'date') {
      const valueMoment = moment(value, 'MM/DD/YYYY');
      if (valueMoment.isValid()) {
        return valueMoment.toDate();
      }
      return null;
    } else {
      return JSON.parse(value);
    }
  }
}

export const reportFormatters = {
  formatReportColumn: (entity: ReportColumnFromApiInterface): ReportColumnInterface => ({
    ...entity,
    renderingFunction: entity.renderingfunction,
    reportId: entity.reportid,
    convertToBaseCurrency: stringBooleanToBoolean(entity.converttobasecurrency),
    decimalPlaces: stringToIntegerNumberOrNull(entity.decimalplaces),
    filterValues: convertStringFilterValuesToStrictTyped(entity.filtervalues, entity.type),
    groupOrder: stringToIntegerNumberOrNull(entity.grouporder),
    isAggregating: stringBooleanToBoolean(entity.isaggregating),
    sortAscending: stringBooleanToBooleanOrNull(entity.sortascending),
    sortOrder: stringToIntegerNumberOrNull(entity.sortorder),
    viewOrder: stringToIntegerNumberOrNull(entity.vieworder),
  }),
  formatClientReportColumn: (entity: ClientReportColumnFromApiInterface): ClientReportColumnInterface => ({
    ...entity,
    clientReportId: entity.clientreportid,
    decimalPlaces: stringToIntegerNumberOrNull(entity.decimalplaces),
    filterValues: convertStringFilterValuesToStrictTyped(entity.filtervalues, entity.type),
    groupOrder: stringToIntegerNumberOrNull(entity.grouporder),
    isAggregating: stringBooleanToBoolean(entity.isaggregating),
    reportColumnId: entity.reportcolumnid,
    sortAscending: stringBooleanToBooleanOrNull(entity.sortascending),
    sortOrder: stringToIntegerNumberOrNull(entity.sortorder),
    viewOrder: stringToIntegerNumberOrNull(entity.vieworder),
  })
}
