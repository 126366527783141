import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './not-found/not-found.component';

const appRoutes: Routes = [
  { path: '', component: PageNotFoundComponent },
  {
    path: ':clientId',
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      },
      {
        path: 'document',
        pathMatch: 'full',
        redirectTo: '',
      },
      {
        path: 'document/:fileId',
        loadChildren: () => import('./viewer').then((module) => module.ViewerModule),
        data: { authRequired: true },
      },
      {
        path: ':fileId',
        loadChildren: () => import('./viewer').then((module) => module.ViewerModule),
        data: { authRequired: false },
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
