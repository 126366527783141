export class Quote {
  constructor(
    public ClientId: number,
    public EvalTime: string,
    public PriceType: string, // bid ask
    public SecurityMarketId: number,
    public Value: number,
    public Currency: string,
  ) {}
}
