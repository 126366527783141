export interface TradeAllocationRequest {
  id?: number;
  tradeId: number;
  accountId: number;
  quantity?: number;
}

export class TradeRequestAllocationFromAPI {
  constructor(public id: string, public traderequestid: string, public accountid: string, public quantity: string) {}
}
