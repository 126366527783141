import { InjectionToken } from '@angular/core';

export const HTTP_SERVICE_URL = new InjectionToken('tradingServiceUrl');
export const HTTP_SERVER_URL = new InjectionToken('serverHttpUrl');

export const USE_PMS_MOCK = new InjectionToken('Use Mock instaed of PMS endpoint response');
export const AG_GRID_KEY =
  'CompanyName=Waterstone Capital Management LP,LicensedApplication=OMS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-019431,ExpiryDate=2_November_2023_[v2]_MTY5ODg4MzIwMDAwMA==f1041ebc35650d636644fef91a20a77c';
export const AG_GRID_KEY_TOKEN = new InjectionToken<string>('AG_GRID_KEY_TOKEN');
export const VDR_USER_SIDE = new InjectionToken('Use user side identify user type in vdr');
export const LOCATION = new InjectionToken<Location>('window.location');

export const V3_ENDPOINT = 'v3/entities/';
export const PORTAL_USER_PERMISSION_SERVICE = 'Portal user permissions service';
